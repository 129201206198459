
import { mapState, mapGetters } from 'vuex'
import bannerRedirect from '~/mixins/common/banner-redirect'
import errorCodeStrings from '~/mixins/common/error-code-strings'
import baseLogic from '~/mixins/base-logic'
import ServerSide from '~/mixins/serverSide'
import Meta from '~/mixins/meta'
import { globalVar } from '~/utils/globalVar'

export default {
    name: 'Theme3',
    mixins: [baseLogic, ServerSide, bannerRedirect, Meta, errorCodeStrings],
    data() {
        return {
            isMobileScreen: false,
            // registerModal: false,
            country_code: 'LA',
            globalVar,
        }
    },
    computed: {
        ...mapGetters({
            headerLogo: 'cms/headerLogo',
        }),
        ...mapState({
            menubars: (state) => state.cms.menus.menus,
            cms: (state) => state.cms.cms,
            isLogin: (state) => state.settings.isLogin,
            showHoldBalance: (state) => state.sockets.showHoldBalance,
            displayGetBonusDetail: (state) =>
                state.sockets.displayGetBonusDetail,
            displayGetBonusModalState: (state) =>
                state.sockets.displayGetBonusModal,
            withdrawalStatus: (state) => state.sockets.withdrawalStatus,
            displayWithdrawalSocketState: (state) =>
                state.sockets.displayWithdrawalSocket,
        }),
        navbarName() {
            if (this.isMobileOnly()) {
                return 'Theme3MobileNavBar'
            }
            return 'Theme3DesktopNavBar'
        },
        displayGetBonusModal: {
            get() {
                return this.displayGetBonusModalState
            },
            set(value) {
                this.$store.commit('sockets/setDisplayGetBonusModal', value)
            },
        },
        displayWithdrawalSocket: {
            get() {
                return this.displayWithdrawalSocketState
            },
            set(value) {
                this.$store.commit('sockets/setDisplayWithdrawalSocket', value)
            },
        },
    },
    watch: {
        $route(to, from) {
            if (!this.$cookie.get('visit') && !this.isInternational)
                this.$store.dispatch('themes/settings/fetchVisitWebsite')
        },
    },
    created() {
        if (process.client) {
            try {
                // eslint-disable-next-line nuxt/no-globals-in-created
                this.$cookie.set('previous_url', document.referrer)
            } catch (error) {}
        }
    },
    async mounted() {
        this.$store?.dispatch('app/checkIsApp')

        // Fetch error code
        this.getErrorCodeStrings()

        this.showBanner()

        // Get invite and earn domain link (refer-friend page)
        this.$store.dispatch('player/getPlayerInviteAndEarnDomain')
        this.getFaviconIcon()
        if (document.referrer) {
            this.$cookie.set('previous_url', document.referrer)
        }
        if (this.$cookie?.get('is_change_language')) {
            this.$store.commit('settings/changeLanguageLoading', true)
        }
        if (this.handleCookie('wl_locale')) {
            this.$recaptcha.language = this.handleCookie('wl_locale')
        }

        // Fetch visit API to get the banner ID
        if (this.isInternational) {
            this.$store.dispatch('themes/settings/fetchVisitWebsite')
        }
        if (
            !window?.sessionStorage?.getItem('visit') &&
            !this.isInternational
        ) {
            this.$store.dispatch('themes/settings/fetchVisitWebsite')
            window.sessionStorage?.setItem('visit', 'true')
        }

        if (this.menubars.length <= 0)
            this.$store.dispatch('cms/menus/getMenubars')
        // Call this store action only when the user is not on the home page and mobile device
        if (this.$route.path !== '/' && !this.isMobileOnly())
            this.$store.dispatch('cms/cmsComponentCode', {
                platform: 1,
                component_code: 'DesktopBackground',
            })
        // Call this store action only when the user is not on the home page but on mobile device
        if (this.$route.path !== '/' && this.isMobileOnly())
            this.$store.dispatch('cms/cmsComponentCode', {
                platform: 2,
                component_code: 'MobileBackground',
            })
        // if (this.cms.length <= 0) this.$store.dispatch('cms/cms', {})
        this.$store.dispatch('settings/supportCountryAndLanguage')
        this.$store.dispatch('themes/settings/getExchangeRate')

        // If the website is agent website, display agent floating icons
        this.$store.dispatch('cms/floatingIcons')
        const cookieCountryCode = this.$cookie?.get('country_code')

        if (this.isInternational) {
            this.country_code =
                cookieCountryCode === 'GO'
                    ? this?.$cookie?.get('global_reg')
                    : cookieCountryCode
        } else {
            this.country_code = 'LA'
        }
        await this.setDefaultLanguages()
        if (this.$cookie.get('currency')) {
            await this.$store.dispatch(
                'settings/operationSetting',
                this.$cookie.get('currency')
            )
        }
        if (this.$cookie.get('token')) {
            this.initSocketTheme(globalVar.themes.theme3.value)
            await this.$store.dispatch(
                'themes/player-bank/getBankPlayerAccount'
            )
        }
        await this.$store.commit('settings/changeLanguageLoading', false)
        this.$cookie.remove('is_change_language')
    },
    methods: {
        onResize() {
            if (process.browser) {
                const MOBILE_SCREEN_WIDTH = 1300
                this.isMobileScreen = window.innerWidth < MOBILE_SCREEN_WIDTH
                this.$store.commit(
                    'themes/settings/setIsMobile',
                    this.isMobileScreen
                )
                return null
            }
            this.isMobileScreen = false
            this.$store.commit(
                'themes/settings/setIsMobile',
                this.isMobileScreen
            )
        },
        closeHoldBalanceDialog() {
            this.$store.commit('sockets/setShowHoldBalance', false)
        },
    },
}
